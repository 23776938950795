import React from 'react'
import { AuthContext } from '../../providers/Auth.js'
import { ModalContext } from '../../providers/Modal.js'
import { Colors } from '../../theme/Colors.js'
import { BudgetCategoriesForm } from './BudgetCategoriesForm.js'
import { useCategoryBudgets } from './BudgetHooks.js'
import { 
  TableContainer,
  InteractiveRow,
  Cell,
  Header 
} from '../../components/Tables.js'

const BudgetCategoriesTable = props => {
  return (  
    <>
    <TableContainer id="main-table">
        <thead>
          <tr>
            <Header 
			        key = 'category' 
			        Align = 'left'
		    	    HideMobile = { false }
		    		>
	    	    	Categoria
		    		</Header>
				    <Header 
			        key = 'share' 
			        Align = 'right'
		    	    HideMobile = { false }
				    >
		    	    Distribuição
				    </Header>
          </tr>
        </thead>
        <tbody>
        	{ RenderRows() }
        </tbody>
    </TableContainer>
    </>
  )
}

const RenderRows = () => {
	const { user } = React.useContext(AuthContext)  
	const { modal, setModal } = React.useContext(ModalContext)
  const rows = useCategoryBudgets(user)
	return (
		Object.keys(rows).map((item, index) => (
			<InteractiveRow 
      	key = { 'row-' + index } 
      	onClick = { () => setModal({
          ...modal, 
          title: item,
          display: null,
          body: <BudgetCategoriesForm
            itemName = {item}
            shareValue = {rows[item]}
          />
        })}
    	>
      	<Cell
          Color = {Colors.Dark}
          Weight = '900'
          Transform = 'uppercase'
        >
          {item}
        </Cell>
        <Cell
          Align='right'
          Weight = '900' 
          Color = {Colors.Dark} 
        >
          {rows[item] * 100}%
        </Cell>
    	</InteractiveRow>
		))
	)
}

export  { BudgetCategoriesTable }