import React, { useState } from 'react';
import { ModalContext } from '../../providers/Modal.js';
import { AuthContext } from '../../providers/Auth.js';
import { MonthKeys } from '../../providers/DefaultObjects.js';
import { 
	getDatabase, 
	ref, 
	set, 
	push, 
	serverTimestamp, 
	remove, 
	increment 
} from 'firebase/database';
import { 
	SmallDangerButton, 
	SmallPrimaryButton 
} from '../../components/Buttons.js';
import { 
	FormCompact, 
	FormPriceCompact, 
	FormDateCompact, 
	FormSelectField, 
	FormFieldCompact, 
	FormBoolCompact
} from '../../components/Forms.js';


export const TransactionForm = props => {
	const db = getDatabase();
  const { user } = React.useContext(AuthContext);
  const { modal, setModal } = React.useContext(ModalContext);
  const fields = Object.keys(props.InputFields);
  const values = Object.values(props.InputFields);
  const initialFieldValues = {};
  fields.map((item, index) => (
  	initialFieldValues[item] = values[index].value
  ));
	const [inputProps, setInputProps] = useState(
		initialFieldValues
	);
	const SubmitExpense = (event, oldValues) => {
		const postExpense = inputProps.id !== ''
			? ref(
					db, 
					`${props.Path}/${user.uid}/${inputProps.id}`
				)
			: push(ref(
					db, 
					`${props.Path}/${user.uid}`
				));

		setModal({
      ...modal,
      display: 'none',
      body: 'Clique no X para fechar'
    });

		set(postExpense, {
  		...inputProps,
			isRecurring: false,
			createdAt: serverTimestamp(),
  		updatedAt: serverTimestamp()
		});

    const newValue = parseFloat(inputProps.value);
    const oldValue = parseFloat(oldValues.value.value) || 0;
    const newDate = inputProps.date;
    const oldDate = oldValues.id.value === ''
    	? newDate 
    	: oldValues.date.value;
    
    SummaryBudget(
    	db, 
    	oldValue, 
    	oldDate, 
    	newValue, 
    	newDate, 
    	inputProps.category
    );
		event.preventDefault();
	}

	const DeleteExpense = event => {
		const db = getDatabase();
		const toRemove = ref(
			db, 
			`${props.Path}/${user.uid}/${inputProps.id}`
		);
    remove(toRemove);

		setModal({
      ...modal,
      display: 'none'
    });
		const oldValue = inputProps.value;
		const referenceDate = inputProps.date;
    SummaryBudget(
    	db, 
    	oldValue, 
    	referenceDate, 
    	0, 
    	referenceDate, 
    	inputProps.category
    );
		event.preventDefault();
	};

	const SummaryBudget = (
		db, 
		oldValue, 
		oldDate, 
		newValue, 
		newDate, 
		categoryPath
	) => {

		let oldMonth = parseInt(oldDate.substring(5,7))-1;
		let oldYear = parseInt(oldDate.substring(0,4));
		
		let newMonth = parseInt(newDate.substring(5,7))-1;
		let newYear = parseInt(newDate.substring(0,4));

		let summary = ref(
			db, 
			`financesSummary/${user.uid}/${MonthKeys[oldMonth]}-${oldYear}/${props.Path}/total`
		);
		set(summary, increment(oldValue*-1));
		
		summary = ref(
			db, 
			`financesSummary/${user.uid}/${MonthKeys[newMonth]}-${newYear}/${props.Path}/total`
		);
		set(summary, increment(newValue));
		
		let summaryType = ref(
			db,
			`financesSummary/${user.uid}/${MonthKeys[oldMonth]}-${oldYear}/${props.Path}/${categoryPath}`
		);
		set(summaryType, increment(oldValue * -1));
		summaryType = ref(
			db,
			`financesSummary/${user.uid}/${MonthKeys[newMonth]}-${newYear}/${props.Path}/${categoryPath}`
		);
		set(summaryType, increment(newValue));
	}

	return (
		<FormCompact>
			{RenderInputs(
				inputProps, 
				setInputProps, 
				props.InputFields
			)}
			<SmallPrimaryButton
				key = 'submit-button'
				onClick = {(event) => SubmitExpense(
					event, 
					props.InputFields
				)}
				Disabled = {inputProps.value && 
					user.uid && 
					inputProps.category
					? false 
					: true
				}
			>
				Salvar
			</SmallPrimaryButton>
			<SmallDangerButton
				key = 'delete-button'
				onClick = {(event) => DeleteExpense(event)}
				Disabled = {inputProps.id ? false : true}
			>
				Excluir
			</SmallDangerButton>
		</FormCompact>
	)
}

const RenderInputs = (
	inputProps, 
	setInputProps, 
	fields
) => {
	const inputs = Object.values(fields)
	return (
		inputs.map((item, index) => (
			RenderInputField(
				inputProps, 
				setInputProps, 
				item, 
				index
		)))
	)
}

const RenderInputField = (
	inputProps, 
	setInputProps, 
	input, 
	index
) => {
	if (input.type === 'price') {
		return (
			<FormPriceCompact
				key = {input.name}
				id = {input.name}
				name = {input.name}
				placeholder = 'R$ 0,00'
				value = {inputProps[input.name]}
				onClick = {inputValue => {
					setInputProps({
						...inputProps, 
						[input.name]: inputProps[input.name] * -1
				})}}
				onChange = {inputValue => {
					setInputProps({
						...inputProps, 
						[input.name]: inputValue.target.value
				})}}
			/>
		)		
	}
	if (input.type === 'text') {
		return (
			<FormFieldCompact
				key = {input.name}
				id = {input.name}
				name = {input.name}
				type = 'text'
				placeholder = 'Item'
				value = { inputProps[input.name] }
				onChange = {
					inputValue => setInputProps({
						...inputProps, 
						[input.name]: inputValue.target.value
				})}
			/>
		)		
	}
	if (input.type === 'date') {
		return (
			<FormDateCompact
				key = {input.name}
				id = {input.name}
				name = {input.name}
				type = 'date'
				value = {inputProps[input.name]}
				onChange = {
					inputValue => setInputProps({
						...inputProps, 
						[input.name]: inputValue.target.value
				})}
			/>
		)		
	}
	if (input.type === 'boolean') {
		return (
			<FormBoolCompact
				key = {input.name}
				id = {input.name}
				name = {input.name}
				type = 'checkbox'
				checked = {inputProps[input.name]}
				label = 'Ativo'
				onChange = {
					inputValue => setInputProps({
						...inputProps, 
						[input.name]: inputValue.target.checked
				})}
			/>
		)		
	}
	if (input.type === 'options') {
		const RenderOptions = options => {	
			return (
				options.map((item, indes) => (
					<option 
						key = {item} 
						value = {item}
					>
						{item}
				</option>
				))
			)
		}
		return (
			<FormSelectField
				key = {input.name}
				id = {input.name}
				name = {input.name}
				value = {inputProps[input.name]}
				onChange = {
					inputValue => setInputProps({
						...inputProps, 
						[input.name]: inputValue.target.value
				})}
			>
				{RenderOptions(input.options)}
			</FormSelectField>
		)		
	}
}