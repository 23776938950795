import React from 'react';

import { OneColumnContent } from '../components/ContentAreas.js'
import { NavTitle } from '../components/Titles.js'
import { FooterButton } from '../components/Buttons.js'


const Page404 = () => {
  return (
    <>
      <OneColumnContent>
        <NavTitle>Página não encontrada</NavTitle>
      </OneColumnContent>
      <FooterButton/>
    </>
  );
}

export default Page404;