import { createRoot } from 'react-dom/client'
import App from './App.js'
import { AuthProvider } from './providers/Auth.js'
import { ModalProvider } from './providers/Modal.js'


const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<AuthProvider>
		<ModalProvider>
			<App />
		</ModalProvider>
	</AuthProvider>
	);
