export const MonthNames = {
  0: 'Janeiro', 1: 'Fevereiro', 2: 'Março',
  3: 'Abril', 4: 'Maio', 5: 'Junho',
  6: 'Julho', 7: 'Agosto', 8: 'Setembro',
  9: 'Outubro', 10: 'Novembro', 11: 'Dezembro',
}

export const MonthKeys = {
  0: 'january', 1: 'february', 2: 'march',
  3: 'april', 4: 'may', 5: 'june',
  6: 'july', 7: 'august', 8: 'september',
  9: 'october', 10: 'november', 11: 'december',
}

