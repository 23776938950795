import React from 'react';
import styled from "styled-components";
import { Colors } from '../theme/Colors.js'
import { Shadow } from '../theme/BasicStyles.js'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'


const ButtonContainer = styled.button`
  font-size: 14px;
  font-family: sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 60px;
  display: block;
  border-radius: 3px;
  color: ${ Colors.Dark };
  background-color: ${ Colors.Light };
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  border: 0px;
  margin-bottom: 12px;
  cursor: pointer;
  ${ Shadow };

  &:disabled {
    background-color: ${ Colors.LightGray };
  }

  &:hover {
  	background-color: ${ Colors.LightGray };
  }
`

const Button = props => {
  return(
    <ButtonContainer 
      disabled={ props.Disabled || false }
      onClick = { props.onClick }
    >
      { props.children }
    </ButtonContainer>
  )
}

const PrimaryButtonContainer = styled(ButtonContainer)`
	color: ${ Colors.Light };
  background-color: ${ Colors.Blue };
    
  &:disabled:hover {
    background-color: ${ Colors.LightGray };
  }

  &:hover {
  	background-color: ${ Colors.BlueHover };
	}
`

const PrimaryButton = props => {
  return (
    <PrimaryButtonContainer 
      disabled = { props.Disabled || false }
      onClick = { props.onClick }
    >
      { props.children }
    </PrimaryButtonContainer>
  )
}

const SmallPrimaryContainer = styled(PrimaryButtonContainer)`
  height: 48px;
  line-height: 48px;
`

const SmallPrimaryButton = props => {
  return (
    <SmallPrimaryContainer 
      disabled = { props.Disabled || false }
      onClick = { props.onClick }
    >
      { props.children }
    </SmallPrimaryContainer>
  )
}

const DangerButtonContainer = styled(PrimaryButtonContainer)`
  color: ${ Colors.Light };
  background-color: ${ Colors.Red };
    
  &:disabled:hover {
    background-color: ${ Colors.LightGray };
  }

  &:hover {
    background-color: ${ Colors.DarkRed };
  }
`

const DangerButton = props => {
  return (
    <DangerButtonContainer 
      disabled = { props.Disabled || false }
      onClick = { props.onClick }
    >
      { props.children }
    </DangerButtonContainer>
  )
}

const SmallDangerContainer = styled(DangerButtonContainer)`
  height: 48px;
  line-height: 48px;
`

const SmallDangerButton = props => {
  return (
    <SmallDangerContainer 
      disabled = { props.Disabled || false }
      onClick = { props.onClick }
    >
      { props.children }
    </SmallDangerContainer>
  )
}

const FooterButtonClass = styled(ButtonContainer)`
	width:100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-bottom: 0px;

  @media(max-width: 992px) {
    height: 100px;
    padding-bottom: 40px;
  }
`

const FooterButton = props => {
  return(
    <FooterButtonClass
      onClick = { () => window.history.back() }
    >
      Voltar
    </FooterButtonClass>
  )
}

const NavContainer = styled.div`
  background-color: transparent;
  border: 1px white solid;
  border-radius: 24px;
  max-width: 100px;
  margin: -12px auto 36px;
  height: 24px;
  vertical-align: middle;
  cursor: pointer;
  color: ${ Colors.Light };

  @media (max-width: 992px) {
    padding: 12px;
  }
`

const NavButton = props => {
  return(
    <NavContainer>
      <ArrowLeftIcon
        onClick = { props.onClickBackwards } 
        style={{ margin: '0 24px 0 0' }}
      />
      <ArrowRightIcon
        onClick = { props.onClickForward } 
        style={{ margin: '0 0 0 24px'}}
      />
    </NavContainer>
  )
}

export { Button, PrimaryButton, DangerButton, 
    FooterButton, SmallPrimaryButton, SmallDangerButton, NavButton }