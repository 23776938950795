export const Colors = {
    Dark: '#444',
    Gray: '#aaa',
    Light: 'white',

    Blue: 'rgba(50,72,190,1)',
    Green: 'rgb(113, 218, 97)',
    Red: 'rgb(208, 68, 26)',

    LightGray: '#bbb',
    TransparentGray: 'rgba(50, 50, 50, 0.05)',
    DarkRed: 'rgb(168, 28, 6)',

    Paragraph: '#969696',

    BlueHover: 'rgba(40,60,180,1)',
    Hover: '#f5f5f5',

}