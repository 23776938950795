import { useEffect, useState } from 'react'
import { 
  getDatabase,
  ref,
  onValue,
  query,
  limitToLast
} from 'firebase/database'

export const useFinancesDetails = (
  path,
  user,
  pagination
) => {
  const [rows, setRows] = useState([])
  const db = getDatabase()

  useEffect(() => {
    const databaseResults = query(ref(
      db, 
      `${path}/${user.uid}`
    ), limitToLast(pagination))

    const handleSnapshot = (snapshot) => {
      const data = snapshot.val()
      const ids = Object.keys(data)
      const values = Object.values(data)

      if (rows.length !== values.length) {
        const newRows = values.map((item, index) => ({
          ...item,
          id: ids[index],
        }))
        setRows(newRows.reverse())
      }
    }
    onValue(databaseResults, handleSnapshot)

    return () => {
      onValue(databaseResults, handleSnapshot)
    }
  }, [db, path, pagination, rows, user])

  return rows
}