import React, { useState } from 'react';
import { ModalContext } from '../../providers/Modal.js';
import { AuthContext } from '../../providers/Auth.js';
import { 
	getDatabase, 
	ref, 
	set, 
	remove 
} from 'firebase/database';
import { 
	SmallDangerButton, 
	SmallPrimaryButton 
} from '../../components/Buttons.js';
import { 
	FormCompact, 
	FormPriceCompact 
} from '../../components/Forms.js';

export const BudgetCategoriesForm = props => {
  const { user } = React.useContext(AuthContext);
  const { modal, setModal } = React.useContext(ModalContext);
	const [inputProps, setInputProps] = useState(props.shareValue * 100);
	const SubmitGoal = event => {
		const db = getDatabase();
		const postGoal = ref(
			db, 
			`goalsCategories/${user.uid}/${props.itemName}`
		);

		set(postGoal, inputProps / 100);		
		setModal({
      ...modal,
      display: 'none',
      body: 'Clique no X para fechar'
    })
		event.preventDefault()
	}

	const DeleteGoal = event => {
		const db = getDatabase()
		const toRemove = ref(
			db, 
			`goalsCategories/${user.uid}/${props.itemName}`
		)
    remove(toRemove)

		setModal({
      ...modal,
      display: 'none'
    })
		event.preventDefault()
	}

	return (
		<FormCompact>
			<FormPriceCompact
				label = 'Distribuição'
				key = 'share'
				name = 'share'
				placeholder = '33%'
				value = { inputProps }
				onChange = { inputValue => {setInputProps(
					inputValue.target.value
				)}}
			/>
			<SmallPrimaryButton
				key = 'submit-button'
				onClick = { event => SubmitGoal(event) }
				Disabled = { user.uid &&
					inputProps !== 0 ? 
					false : true
				}
			>
				Salvar
			</SmallPrimaryButton>
			<SmallDangerButton
				key = 'delete-button'
				onClick = { event => DeleteGoal(event) }
				Disabled = { inputProps !== 0
					? false : true }
			>
				Excluir
			</SmallDangerButton>
		</FormCompact>
	)
}