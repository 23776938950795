import React from 'react'
import { AuthContext } from '../../providers/Auth.js'
import { ModalContext } from '../../providers/Modal.js'
import { Colors } from '../../theme/Colors.js'
import { BudgetForm } from './BudgetForm.js'
import { useMonthlyBudgets } from './BudgetHooks.js'
import { 
  TableContainer,
  InteractiveRow,
  Cell,
  Header 
} from '../../components/Tables.js'

const BudgetTable = props => {
  return (  
    <>
    <TableContainer id="main-table">
        <thead>
          <tr>
            <Header 
			        key = 'year-month' 
			        Align = 'left'
		    	    HideMobile = { false }
		    		>
	    	    	Mês
		    		</Header>
            <Header 
			        key = 'expense' 
			        Align = 'center'
		    	    HideMobile = { false }
				    >
		    	    Despesas
				    </Header>
				    <Header 
			        key = 'saving' 
			        Align = 'right'
		    	    HideMobile = { false }
				    >
		    	    Economias
				    </Header>
          </tr>
        </thead>
        <tbody>
        	{ RenderRows() }
        </tbody>
    </TableContainer>
    </>
  )
}

const RenderRows = () => {
	const { user } = React.useContext(AuthContext)  
	const { modal, setModal } = React.useContext(ModalContext)
	const rows = useMonthlyBudgets(user)
	 const monthsDict = {
  	january: 'Janeiro', 
    february: 'Fevereiro', 
    march: 'Março',
  	april: 'Abril', 
    may: 'Maio', 
    june: 'Junho',
  	july: 'Julho', 
    august: 'Agosto', 
    september: 'Setembro',
  	october: 'Outubro', 
    november: 'Novembro', 
    december: 'Dezembro'
  }

	return (
		Object.values(rows).map((item, index) => (
			<InteractiveRow 
      	key = { 'row-' + item.month } 
      	onClick = { () => setModal({
          ...modal, 
          title: monthsDict[item.month] + ' ' + item.year,
          display: null,
          body: <BudgetForm
            newValues = {{
              expenses: item.expenses, 
              savings: item.savings
            }}
            Month = {item.month} 
            Year = {item.year}
      		/>
        })}
    	>
      	<Cell
          Color = {Colors.Dark}
          Weight = '900' 
        >
          {monthsDict[item.month].substring(0,3) + ' / ' + item.year}
        </Cell>
        <Cell
          Align='center'
          Weight = '900' 
          Color = { Colors.Red } 
        >
          R$ { parseFloat(item.expenses).toFixed(2).replace('.', ',') }
        </Cell>
        <Cell
          Align='right'
          Weight = '900' 
          Color = { Colors.Green } 
        >
          R$ { parseFloat(item.savings).toFixed(2).replace('.', ',') }
        </Cell>
    	</InteractiveRow>
		))
	)
}

export  { BudgetTable }