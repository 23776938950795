import { useEffect, useState } from 'react';
import { MonthKeys } from '../../providers/DefaultObjects.js';
import {
  getDatabase,
  ref,
  onValue,
  query,
  limitToLast
} from 'firebase/database';

export const useDetailedSummary = (
  path,
  user,
  referenceMonth,
  referenceYear,
  pagination
) => {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const db = getDatabase();

  useEffect(() => {
    const databaseResults = query(
      ref(
        db,
        `financesSummary/${user.uid}/${MonthKeys[referenceMonth]}-${referenceYear}/${path}`
      ),
      limitToLast(pagination)
    );

    const handleSnapshot = (snapshot) => {
      const data = snapshot.val();
      const ids = data ? Object.keys(data) : ['total'];
      const values = data ? Object.values(data) : [0];
      const newTotal = values.reduce((toSum, element) => toSum + element, 0);

      if (total !== newTotal) {
        const newRows = values.map((item, index) => ({
          ...item,
          [ids[index]]: item,
        }));
        setRows(newRows.reverse());
      }

      const sum = values.reduce((toSum, element) => toSum + element, 0);
      setTotal(sum);
    };

    onValue(databaseResults, handleSnapshot);

    return () => {
      onValue(databaseResults, handleSnapshot);
    };
  }, [db, path, referenceYear, total, rows, user, referenceMonth, pagination]);

  return rows;
};
