import React from 'react'
import { ModalContext } from '../../providers/Modal.js'
import { TransactionForm } from './TransactionForm.js'
import { TableContainer, InteractiveRow, Cell, Header } from '../../components/Tables.js'


const TransactionTable = props => {
  return (
    <>
      <TableContainer id="main-table">
        <thead>
          <tr>
            {RenderHeader(
              props.DisplayColumns, 
              props.Inputs
            )}
          </tr>
        </thead>
        <tbody>
          {RenderRows(
              props.Rows,
              props.Path, 
              props.Inputs, 
              props.DisplayColumns, 
              props.Colors, 
              props.Form
            ) 
          }
        </tbody>
      </TableContainer>
    </>
  )
}

const RenderHeader = (columns, keys) => {
  const columnNames = Array(0)
  columns.map((item, index) =>(
    columnNames.push(keys[item].column)
  ))

  let alignments = Array(columnNames.length - 2).fill('center')
  alignments.unshift('left')
  alignments.push('right')

  let hideMobileData = Array(columnNames.length - 2).fill(true)
  hideMobileData.unshift(false)
  hideMobileData.push(false)
  
  return(
    columnNames.map((item, index) => (
      <Header 
        key = { item } 
        Align = { alignments[index] }
        HideMobile = { hideMobileData[index] }
      >
        { item }
      </Header>
    ))
  )
}

const RenderRows = (rows, path, inputFields, sortedItems, colors, FormBody) => {
  const { modal, setModal } = React.useContext(ModalContext)

  return (
    rows.map((item, index) => (
      <InteractiveRow 
        key = { 'row-' + index }
        onClick = { () => setModal({
          ...modal,
          title: 'Editar',
          display: null,
          body: <TransactionForm
                  Path = { path }
                  InputFields = { FormBody(item) }
                />
        })}
      >
        { RenderCells(item, sortedItems, inputFields, colors) }
      </InteractiveRow>
    ))
  )
}

const RenderCells = (rowData, sortedItems, inputFields, colors) => {

  let alignments = Array(sortedItems.length - 2).fill('center')
  alignments.unshift('left')
  alignments.push('right')
  
  let weights = Array(sortedItems.length - 2).fill('300')
  weights.unshift('900')
  weights.push('900')

  let transformations = Array(sortedItems.length - 2).fill(null)
  transformations.unshift('uppercase')
  transformations.push('uppercase')

  let hideMobileData = Array(sortedItems.length - 2).fill(true)
  hideMobileData.unshift(false)
  hideMobileData.push(false)

  const decorationColor = parseInt(rowData.value) > 0 ? colors[0] : colors[1]
  let colorDecorations = Array(sortedItems.length - 2).fill('#aaa')
  colorDecorations.unshift(decorationColor)
  colorDecorations.push(decorationColor)

  return (
    sortedItems.map((item, index) => (
      <Cell 
        key = { rowData.id + index }
        Color = { colorDecorations[index] } 
        Transform = { transformations[index] }
        Weight = { weights[index] }
        Align = { alignments[index] }
        HideMobile = { hideMobileData[index] }
      >
        { ParseContent(rowData[item], inputFields[item].type) }
      </Cell>
    ))
  )
}

const ParseContent = (content, type) => {
  if (type === 'price') {
    return (
      `R$ ${parseFloat(content)
        .toFixed(2).replace('.', ',')}`
    )
  }
  if (type === 'date') {
    return (
      content.substring(8,10) + '/' + 
      content.substring(5,7) + '/' + content.substring(0,4)
    )
  }
  if (type === 'boolean') {
    const icon = content ? '✅' : '❌'
    return (
      icon
    )
  }
  return (
    content
  )
}

export  { TransactionTable }