import React, { Component }  from 'react'

import { getDatabase, ref, onValue, remove } from "firebase/database";

import { TwoColumnsContent } from '../../components/ContentAreas.js'
import { PageTitle } from '../../components/Titles.js'
import { InfoCard } from '../../components/Cards.js'
import { FooterButton } from '../../components/Buttons.js'
import { LeadForm } from './LeadForm.js'

import Close from '@mui/icons-material/Close'


const db = getDatabase()

class CustomForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leads: [] 
    }
  }

  componentDidMount() {
    this.refreshList()
  }

  refreshList() {
    const leads = ref(db, 'leads/')
    
    onValue(leads, (snapshot) => {
      const data = snapshot.val()
      this.setState({ leads: data })
    })
  }

  deleteLead(leadID) {
    const leadToRemove = ref(db, 'leads/' + leadID)
    remove(leadToRemove)
  }

  renderLeads() {
    const CardIDs = Object.keys(this.state.leads) 
    const CardData = Object.values(this.state.leads)
    
    return (
      CardData.map((item, index) => (
        <InfoCard
          key = { CardIDs[index] }
          title = { item.name }
          text = { item.email }
        >
          <Close 
            onClick={ () => this.deleteLead(CardIDs[index]) }
          />
        </InfoCard>
      ))
    )
  }

  render() {
    return (
      <>
        <TwoColumnsContent
          columnOne = {
            <>
              <PageTitle>Formulário</PageTitle>
              <LeadForm/>
            </>
          }
          columnTwo = {
            <>
              <PageTitle text="Inscritos"/>
              { this.renderLeads() }
            </>            
          }
        />
        <FooterButton/>
      </>
)}}

export default CustomForm