import React from 'react'
import styled from "styled-components"
import { initializeApp } from "firebase/app"
import { firebaseConfig } from "../components/Firebase.js"
import { GoogleAuthProvider } from "firebase/auth"
import { getAuth, signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth"
import { getDatabase, ref, get, child, set, serverTimestamp } from "firebase/database";
import { AuthContext } from '../providers/Auth.js'
import { IconStyle } from '../theme/BasicStyles.js'
import { ActionCard } from '../components/Cards.js'
import Login from '@mui/icons-material/Login'
import Logout from '@mui/icons-material/Logout'


initializeApp(firebaseConfig)
const provider = new GoogleAuthProvider()
const auth = getAuth()

const LoginNavBtn = styled.button`
  cursor: pointer; 
  border: none;
  background-color: transparent;
  vertical-align: middle;
  padding: 24px;
  color: white;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  float: right;

  &:hover {
    background-color:rgba(0,0,0,0.05);
  }
`

export const AuthStatus = () => {
  const { user, setUser } = React.useContext(AuthContext)    
  onAuthStateChanged(auth, (userData) => {
    if (userData === null) {
      setUser({ 
        name: 'Visitante',
        email: null,
        uid: null,
        logged: false
      })
    } else if (userData.uid !== user.uid) {
      setUser({ 
        name: userData.displayName,
        email: userData.email,
        uid: userData.uid,
        logged: true,
        photo: userData.photoURL
      })
      RegisterUser(userData)
    }
  })
  return
}


export const AuthButton = () => {
  const { user } = React.useContext(AuthContext)
  if (user.logged) {
    return ( 
      <LoginNavBtn 
        onClick={ () => signOut(auth) }
      >
      <Logout style={ IconStyle }></Logout>
      </LoginNavBtn>
    )
  }
  return ( 
    <LoginNavBtn 
      onClick={ () =>  signInWithPopup(auth, provider) }
    >
    <Login style={ IconStyle }></Login>
    </LoginNavBtn>
  )
}

const RegisterUser = userData => {
  const db = getDatabase()
  get(child(ref(db), 'users/' + userData.uid))
      .then((snapshot) => {
        if (!snapshot.exists()) {
          const postUser = ref(db, 'users/' + userData.uid)
          set(postUser, {
            name: userData.displayName,
            email: userData.email,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          })
        }
    })
}

export const AuthCard = () => {
  const { user } = React.useContext(AuthContext)
  if (user.logged) {
    return ( 
      <ActionCard 
        emoji="🚪" 
        label="Logout" 
        onClick={ () => signOut(auth) }
      />
    )
  }
  return ( 
    <ActionCard 
      emoji="🔑" 
      label="Login" 
      onClick={ () => signInWithPopup(auth, provider) }
    />
  )
}