export const Shadow = `
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.15);
`
export const IconStyle = {
    FontSize: '17px !important',
    padding: 0,
    height: '17px',
    VerticalAlign: 'middle',
}