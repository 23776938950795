import React from 'react';
import { BudgetTable } from './BudgetTable.js';
import { BudgetCategoriesTable } from './BudgetCategoriesTable.js';
import { OneColumnContent } from '../../components/ContentAreas.js';
import { DefaultModal } from '../../components/Modals.js';
import { FooterButton } from '../../components/Buttons.js';
import { 
  BudgetInputs, 
  BudgetCategoriesInputs 
} from './InputRules.js';
import { 
  NavTitle, 
  PageSecondaryTitle 
} from '../../components/Titles.js';

const Budgets = () => {
  return (
    <>
      <OneColumnContent>
        <NavTitle>Metas</NavTitle>
        <BudgetTable
          Path="goals/"
          InputFields={BudgetInputs}
        />
        <PageSecondaryTitle>
          Categorias
        </PageSecondaryTitle>
        <BudgetCategoriesTable
          Path="goalsCategories/"
          InputFields={BudgetCategoriesInputs}
        />
      </OneColumnContent>
      <DefaultModal />
      <FooterButton />
    </>
  )
}

export default Budgets