import styled from "styled-components"

import { Shadow } from '../theme/BasicStyles.js'
import { Colors } from '../theme/Colors.js'
import { FontSizes } from '../theme/Fonts.js'


const TableStyles = `
	padding: 12px;
	font-family: sans-serif;
	font-weight: 300;
	border-bottom: solid 1px rgb(221, 221, 221);
	text-align: left;
	color: ${Colors.Gray};
	${ FontSizes.S10 };
`

export const TableContainer = styled.table`
	padding: 12px;
	background-color: white;
	width: 100%;
	border-radius: 4px;
	border-spacing: 0px;
	margin-bottom: 24px;
	${ Shadow }
`

export const SummaryTableContainer = styled.table`
	padding: 12px;
	background-color: transparent;
	width: 100%;
	max-width: 382px;
	border-radius: 4px;
	border-spacing: 0px;
	margin-bottom: 24px;
	border: 1px solid white;
	margin: 0 auto 16px;

	@media (max-width: 992px) {
		max-width: 322px;
	}	
`

export const InteractiveRow = styled.tr`
    cursor: pointer; 
	&:hover {
    	background-color: ${ Colors.TransparentGray };
	}
`

const HeaderContainer = styled.th`
	${ TableStyles }
	font-weight: 500;
	text-align: ${props => props.align};
`

export const HeaderContainerDesktop = styled(HeaderContainer)`
	@media (max-width: 992px) {
		display: none;
	}	
`

export const CellContainer = styled.td`
	${ TableStyles }
	color: ${props => props.color};
	text-align: ${props => props.align};
	text-transform: ${props => props.transform};
	font-weight: ${props => props.weight};
	line-height: 38px;
`

export const CellContainerDesktop = styled(CellContainer)`
	@media (max-width: 992px) {
		display: none;
	}
`

export const Header = props => {
	if (props.HideMobile) {
		return (
			<HeaderContainerDesktop
				align = { props.Align }
			>
				{props.children}
			</HeaderContainerDesktop>
		)
	}
	return(
		<HeaderContainer
			align = { props.Align }
			color = { props.Color }
		>
			{props.children}
		</HeaderContainer>
	)		
}

export const Cell = props => {
	if (props.HideMobile) {
		return (
			<CellContainerDesktop
				align = { props.Align }
				color = { props.Color } 
          		transform = { props.Transform }
        		weight = { props.Weight }
        		colSpan = { props.Colspan }
			>
				{props.children}
			</CellContainerDesktop>
		)
	}
	return(
		<CellContainer
			align = { props.Align }
			color = { props.Color } 
      		transform = { props.Transform }
    		weight = { props.Weight }
    		colSpan = { props.Colspan }
		>
			{props.children}
		</CellContainer>
	)		
}