import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../providers/Auth.js'
import { ModalContext } from '../../providers/Modal.js'
import { useDetailedSummary } from './SummaryHooks.js'
import { useFinancesDetails } from './TransactionHooks.js'
import { TransactionTable } from './TransactionTables.js'
import { TransactionForm } from './TransactionForm.js'
import { SetFormBody } from './FormBodyHelper.js'
import { OneColumnContent } from '../../components/ContentAreas.js'
import { SummaryHeader } from '../../components/SummaryHeader.js'
import { NavTitle } from '../../components/Titles.js'
import { Button } from '../../components/Buttons.js'
import { DefaultModal } from '../../components/Modals.js'
import { FooterButton } from '../../components/Buttons.js'
import { ExpenseInputs } from './InputRules.js'
import { Colors } from '../../theme/Colors.js'

const Expenses = () => {
  const { user } = useContext(AuthContext)
  const { modal, setModal } = useContext(ModalContext)
  const [pagination, setPagination] = useState(20)
  const [total, setTotal] = useState(0)
  const today = new Date()
  const path = 'expenses'
  const expensesSummary = useDetailedSummary(
    path, 
    user, 
    today.getMonth(), 
    today.getFullYear(),
    100
  )
  const rows = useFinancesDetails(path, user, pagination)

  useEffect (() => {
    const newExpensesTotal = expensesSummary.length > 0 
      ? expensesSummary[0].total 
      : 0
    setTotal(newExpensesTotal)
  }, [expensesSummary])

  const handleAddExpenseClick = () => {
    const today = new Date()
    setModal({
      ...modal,
      title: 'Adicionar',
      display: null,
      body: (
        <TransactionForm
          Path={path}
          InputFields={
            SetFormBody(ExpenseInputs,{
              date: today.toISOString().slice(0, 10)
            })}
        />
      ),
    })
  }

  const handleLoadMoreClick = () => {
    setPagination(pagination + 20)
  }

  return (
    <>
      <OneColumnContent>
        <NavTitle>Gastos</NavTitle>
        <SummaryHeader
          heading="Esse mês"
          title={'R$ ' + parseFloat(total).toFixed(2).replace('.', ',')}
          caption="Clique para adicionar despesa"
          onClick={handleAddExpenseClick}
        />
        <TransactionTable
          Path={path}
          DisplayColumns={['category', 'name', 'date', 'value']}
          Rows={rows}
          Colors={[Colors.Red, Colors.Green]}
          Inputs={ExpenseInputs}
          Form={transactionData => SetFormBody(ExpenseInputs, transactionData)}
        />
        <Button onClick={handleLoadMoreClick}>Carregar mais</Button>
      </OneColumnContent>
      <DefaultModal />
      <FooterButton />
    </>
  )
}

export default Expenses