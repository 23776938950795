import styled from "styled-components";

import { Colors } from '../theme/Colors.js'
import { FontSizes } from '../theme/Fonts.js'
import { IconStyle } from '../theme/BasicStyles.js'

import ArrowBack from '@mui/icons-material/ArrowBack';


const ReturnIcon = styled.div`
  border-radius:50%;
  display: inline-block;
  margin-right: 12px;
  height:36px;
  width: 36px;
  cursor: pointer;

  & > svg {
    font-size: 24px;
    position: relative;
    top: -4px; 
  }
  &:hover {
    background-color: ${Colors.TransparentGray};
  }
`

export const PageTitle = styled.h1`
  font-family: Montserrat;
  ${ FontSizes.S3 }
  color: ${Colors.Light};

  font-weight: 900;
  text-align: center;
  padding: 12px 0;
  margin: 0 0 12px;
`

export const PageSecondaryTitle = styled(PageTitle)`
  ${ FontSizes.S4 }
`

export const NavTitle = props => {
  return(
      <PageSecondaryTitle>
        <ReturnIcon onClick={ () => window.location.href ='/' }>
          <ArrowBack style={ IconStyle }></ArrowBack>
        </ReturnIcon>
          { props.children}
      </PageSecondaryTitle>
  )
}
