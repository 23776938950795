import React  from 'react'
import styled from "styled-components"
import { ModalContext } from '../providers/Modal.js'
import { Colors } from '../theme/Colors.js'
import { Shadow } from '../theme/BasicStyles.js'
import { FontSizes } from '../theme/Fonts.js'
import Close from '@mui/icons-material/Close'


const ModalBackground = styled.div`
	z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: #5b798e87;
`

const ModalContainer = styled.div`
	margin: 100px auto;
	text-align: center;
    width: 300px;
    padding: 24px;
    border-radius: 4px;
    max-height: 420px;
    overflow: scroll;
	background-color: ${ Colors.Light };
	${ Shadow }

	@media(max-width: 992px) {
    	max-height: none;
    	width: calc(100% - 48px);
    	position: absolute;
    	top: -100px;
    	padding-top: 40%;
    	padding-bottom: 100%;
    }
`

const ModalTitle = styled.h3`
	font-family: Montserrat;
 	${ FontSizes.S6 }
 	color: ${Colors.Dark};

 	font-weight: 900;
 	text-align: center;
	padding: 12px 0;
	margin: 0 0 12px;
`

export const DefaultModal = () => {
	const { modal, setModal } = React.useContext(ModalContext)
	return(
		<ModalBackground
			style = {{ "display" : modal.display }}
		>
			<ModalContainer
				style = {{ "display" : modal.display }}
			>
			    <Close 
			    	style = {{ 
	    				"float": "right" ,
	    				"borderRadius": "50%",
	    				"cursor" : "pointer",
	    			}} 
			    	onClick = {() => setModal({
			    		...modal, 
			    		display: 'none', 
			    		body: null
			    	})}
			    />
				<ModalTitle>
					{ modal.title }
				</ModalTitle>
					{ modal.body }
			</ModalContainer>
		</ModalBackground>
	)
}