import React from 'react';
import styled from "styled-components"
import { useContext } from 'react';
import { AuthContext } from '../../providers/Auth.js';

import { OneColumnContent } from '../../components/ContentAreas.js';
import { NavTitle } from '../../components/Titles.js';
import { FooterButton } from '../../components/Buttons.js';
import { Colors } from '../../theme/Colors.js';
import { 
  TableContainer,
  InteractiveRow,
  Cell,
  Header 
} from '../../components/Tables.js';

const ProfileImage = styled.img`
  border-radius: 50%;
  position: relative;
  text-align: center;
  border: white 2px solid;
`

export default function Profile() {
  const { user } = useContext(AuthContext)
  return (
    <>
        <OneColumnContent>
          <ProfileImage src={user.photo}/>
          <NavTitle>{user.name}</NavTitle>
          <TableContainer id="main-table">
            <thead>
              <tr>
                <Header 
                  key = 'label' 
                  Align = 'left'
                  HideMobile = { false }
                >
                </Header>
                <Header 
                  key = 'value' 
                  Align = 'right'
                  HideMobile = { false }
                >
                </Header>
              </tr>
            </thead>
            <tbody>
              <InteractiveRow 
                key = { 'row-name' }
              >
                <Cell
                  Color = {Colors.Grey}
                >
                  UID
                </Cell>
                <Cell
                  Align='right'
                  Weight = '900' 
                  Color = { Colors.Dark }
                >
                  {user.uid}
                </Cell>
              </InteractiveRow>
              <InteractiveRow 
                key = { 'row-email' }
              >
                <Cell
                  Color = {Colors.Grey}
                >
                  Email
                </Cell>
                <Cell
                  Align='right'
                  Weight = '900' 
                  Color = { Colors.Dark }
                >
                  {user.email}
                </Cell>
              </InteractiveRow>
            </tbody>
        </TableContainer>
        </OneColumnContent>
        <FooterButton/>
    </>
  )
}
