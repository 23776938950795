import React, { useState } from 'react'
import { ModalContext } from '../../providers/Modal.js'
import { AuthContext } from '../../providers/Auth.js'
import { 
	getDatabase, 
	ref, 
	set, 
	remove 
} from 'firebase/database'
import { 
	SmallDangerButton, 
	SmallPrimaryButton 
} from '../../components/Buttons.js'
import { 
	FormCompact, 
	FormPriceCompact 
} from '../../components/Forms.js'

export const BudgetForm = props => {
  const { user } = React.useContext(AuthContext)
  const { modal, setModal } = React.useContext(ModalContext)
	const [inputProps, setInputProps] = useState(props.newValues)

	const SubmitGoal = (event) => {
		const db = getDatabase()
		const postGoal = ref(
			db, 
			`goals/${user.uid}/${props.Month}-${props.Year}`
		)

		set(postGoal, {
  		...inputProps,
  		month: props.Month,
  		year: props.Year
		})
		
		setModal({
      ...modal,
      display: 'none',
      body: 'Clique no X para fechar'
    })

		event.preventDefault()
	}

	const DeleteGoal = event => {
		const db = getDatabase()
		const toRemove = ref(
			db, 
			`goals/${user.uid}/${props.Month}-${props.Year}`
		)
    remove(toRemove)

		setModal({
      ...modal,
      display: 'none'
    })

		event.preventDefault()
	}

	return (
		<FormCompact>
			<FormPriceCompact
				label = 'Despesas'
				key = 'expenses'
				name = 'expenses'
				placeholder = 'R$ 0,00'
				value = { inputProps.expenses }
				onChange = { inputValue => {setInputProps({
					...inputProps, 
					expenses: inputValue.target.value
				})}}
			/>
			<FormPriceCompact
				label = 'Economias'
				key = 'savings'
				name = 'expenses'
				placeholder = 'R$ 0,00'
				value = { inputProps.savings }
				onChange = { inputValue => {setInputProps({
					...inputProps, 
					savings: inputValue.target.value
				})}}
			/>
			<SmallPrimaryButton
				key = 'submit-button'
				onClick = { event => SubmitGoal(event) }
				Disabled = { user.uid &&
					inputProps.expenses !== '' && 
					inputProps.savings !== '' ? 
					false : true
				}
			>
				Salvar
			</SmallPrimaryButton>
			<SmallDangerButton
				key = 'delete-button'
				onClick = { event => DeleteGoal(event) }
				Disabled = { inputProps.expenses !== 0 ||
					inputProps.savings !== 0
					? false : true }
			>
				Excluir
			</SmallDangerButton>
		</FormCompact>
	)
}