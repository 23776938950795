export const FontSizes = {
    S1: `font-size: 60px; 
        line-height: 60px;`,

    S2: `font-size: 56px;
        line-height: 56px;`,

    S3: `font-size: 48px;
        line-height: 48px;`,

    S4: `font-size: 36px;
    line-height: 36px;`,

    S5: `font-size: 30px;
    line-height: 30px;`,

    S6: `font-size: 24px;
    line-height: 24px;`,

    S7: `font-size: 21px;
    line-height: 21px;`,

    S8: `font-size: 17px;
    line-height: 21px;`,

    S9: `font-size: 14px;
    line-height: 21px;`,

    S10: `font-size: 12px;
    line-height: 16px;`
}
