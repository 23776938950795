import React from 'react'


export const ModalContext = React.createContext({})

export const ModalProvider = (props) => {
	const [ modal, setModal ] = React.useState({
    	display: 'none',
    	title: 'Modal',
    	body: 'Insira o conteúdo da modal aqui.',
	})

	return (
		<ModalContext.Provider value = {{ modal, setModal }}>
			{ props.children }
		</ModalContext.Provider>
	)
}
