import React, { Component }  from 'react';

import './RdFormsStyles.css';
import { OneColumnContent } from '../../components/ContentAreas.js'
import { FooterButton } from '../../components/Buttons.js'


class EmbedForm extends Component {
  componentDidMount() {
    if ( window.RDStationForms ) {
      new window.RDStationForms('form-1-133b439efdb25fa943f0', 'UA-160266184-1').createForm();
  }}

  render() {
    return (
      <>
        <OneColumnContent>
          <div className="App">
            <header className="App-header">
              <div role="main" id='form-1-133b439efdb25fa943f0'></div>
            </header>
          </div>
        </OneColumnContent>
        <FooterButton/>
      </>
)}}

export default EmbedForm