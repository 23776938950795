import styled from "styled-components";


const DefaultMargins = styled.div`
  margin: 84px;
  width: 100%;
  @media (max-width: 992px) {
    margin: 64px 12px 160px 12px;
  }
`

const CenteredContent = styled.div`
	max-width: 560px;
	margin: auto;
	text-align: center;
`

const LeftColumn = styled.div`
	width:50%;
	float: left;
	@media(max-width: 992px) {
		width: 100px;
		margin: 0 auto;
		min-width: 320px;
		width: 100%;
		float: none;
		margin-bottom: 80px;
	}
`

const RightColumn = styled(LeftColumn)`
	float: right;
	@media(max-width: 992px) {
		float: none;
	}
`

const OneColumnContent = props => {
	return(
		<DefaultMargins>
			<CenteredContent>
				{ props.children }
			</CenteredContent>
		</DefaultMargins>
	)
}

const TwoColumnsContent = props => {
	return(
		<DefaultMargins>
			<LeftColumn>
				{ props.columnOne }
			</LeftColumn>
			<RightColumn>
				{ props.columnTwo }
			</RightColumn>
		</DefaultMargins>
	)
}

export { OneColumnContent, TwoColumnsContent }