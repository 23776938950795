import React, { useEffect, useState }  from 'react'
import { getDatabase, ref, push, serverTimestamp, set, increment } from "firebase/database"
import { AuthContext } from '../../providers/Auth.js'
import { ModalContext } from '../../providers/Modal.js'
import { MonthKeys } from '../../providers/DefaultObjects.js'
import { TransactionTable } from './TransactionTables.js'
import { TransactionForm } from './TransactionForm.js'
import { SetFormBody } from './FormBodyHelper.js'
import { Colors } from '../../theme/Colors.js'
import { OneColumnContent } from '../../components/ContentAreas.js'
import { SummaryHeader } from '../../components/SummaryHeader.js'
import { NavTitle } from '../../components/Titles.js'
import { DefaultModal } from '../../components/Modals.js'
import { Button, FooterButton, SmallDangerButton } from '../../components/Buttons.js'
import { RecurringExpensesInputs } from './InputRules.js'
import { useFinancesDetails } from './TransactionHooks.js'

const RecurringExpenses = () => {
  const { user } = React.useContext(AuthContext)
  const { modal, setModal } = React.useContext(ModalContext)
  const [total, setTotal] = useState(0)
  
  const db = getDatabase()
  const path = 'recurringExpenses/'
  const displayColumns = ['name', 'category', 'active', 'value']
  const rows = useFinancesDetails(path, user, 100)

  useEffect(() => {
    const valuesToSum = rows.filter(row => row.active === true)
      
    const total = 0
    const totalRecurring = valuesToSum.reduce((accumulator, newValue) => 
        accumulator + parseFloat(newValue.value), total)

    setTotal(totalRecurring)
  }, [rows])

  const SubmitConfirmation = () => {
    setModal({
      ...modal,
      display: null,
      title: '⏱ Adicionar despesas',
      body: ModalBody()
    })
  }

  const ModalBody = () => {
    return (
      <>
        <p>Ao clicar em confirmar, você adicionará os 
        gastos ativos na sua lista de gastos do mês.</p>
        <SmallDangerButton
          onClick = { () => SubmitRecurring() }
        >
          Confirmar
          </SmallDangerButton>
        <Button
          onClick = { () => setModal({...modal, 
            display: 'none'}
          )}
        >
          Fechar
        </Button>
      </>
    )
  }

  const SubmitRecurring = () => {
    const today = new Date()
    const thisMonth = today.getMonth()
    const thisYear = today.getFullYear()

    const toRegister = rows.filter((item) => item.active === true)
    toRegister.map((item, index) => {
      const expenses = push(ref(db, 'expenses/' +  user.uid))
      
      set(expenses, {
        value: item.value,
        category: item.category,
        name: item.name + ' - ⏱',
        date: today.toISOString().slice(0,10),
        isRecurring: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })

      const summary = ref(db, 'financesSummary/' + user.uid + '/' 
        + MonthKeys[thisMonth] + '-' + thisYear + '/expenses/'
        + item.category) 
      set(summary, increment(parseFloat(item.value)))
      return true
    })

    const summary = ref(db, 'financesSummary/' + user.uid + '/' 
      + MonthKeys[thisMonth] + '-' + thisYear + '/expenses/total') 
    set(summary, increment(total))
    setModal({...modal, display: 'none'})
  }

  return(
    <>
      <OneColumnContent>
        <NavTitle>Gastos mensais</NavTitle>
        <SummaryHeader
          heading = 'Contas mensais ativas'
          title = {'R$ ' + parseFloat(total).toFixed(2).replace('.', ',')}
          caption = 'No primeiro dia do mês, as contas ativas ✅ são registradas no menu de gastos.'
          onClick = { () => setModal({
            ...modal, 
            title: 'Adicionar',
            display: null,
            body: <TransactionForm
              Path = { path }
              InputFields = { SetFormBody(RecurringExpensesInputs, {}) }
            />
          })}
        />
        <TransactionTable
          Rows = { rows }
          Colors = { [Colors.Red, Colors.Green] }
          Path = { path }
          DisplayColumns = { displayColumns }
          Inputs = { RecurringExpensesInputs }
          Form = { recurringData => SetFormBody(RecurringExpensesInputs, recurringData) }
        />
      <Button
        onClick = { () => SubmitConfirmation(modal, setModal)}
      >
        Registrar despesas
      </Button>
      </OneColumnContent>
      <DefaultModal/>
      <FooterButton/>
    </>
  )
}

export default RecurringExpenses