import React from 'react';
import styled from "styled-components";

import { Colors } from '../theme/Colors.js'
import { FontSizes } from '../theme/Fonts.js'
import Shuffle from '@mui/icons-material/Shuffle'


const inputStyles = `
	${ FontSizes.S8 };
	border: 1px solid;
	border-radius: 4px;
	border-color: ${ Colors.LightGray };
	padding: 18px;
	width: calc(100% - 24px);
	margin-bottom: 12px;
	-webkit-appearance: none;
	background-color: white;
`

const FormBody = styled.form`
	margin: 0 48px;
`

const FormBodyCompact = styled.form`
	margin: 0px;
`

const FormLabel = styled.label`
	${ FontSizes.S9 };
	font-family: sans-serif;
	color: ${ Colors.Light };
	display: block;
	margin-left: 6px;
`

const FormInput = styled.input`
	${ inputStyles }
`

const FormInputCompact = styled(FormInput)`
	padding: 0 12px;
	height: 48px;
	font-size: 17px;
	
	@media(max-width: 992px) {
    	padding: 12px;

    }
`

const FormSelectCompact = styled.select`
	${ inputStyles }
	width: 100%;
	height: 48px;
	padding: 0px 12px;
	@media(max-width: 992px) {
    	padding: 12px;
    	height: 72px;
    }
`

const BooleanInputContainer = styled.input`
	float: left;
	height: 36px;
	width: 24px;
	margin-bottom: 12px;
`

const BoolLabel = styled(FormLabel)`
	color: ${ Colors.Dark };
	position: relative;
    display: inline-block;
    left: -110px;
    font: sans-serif;
    ${ FontSizes.S8 };
    line-height: 42px;
`

const ModalLabel = styled(FormLabel)`
	color: ${ Colors.Dark };
	text-align: left;
	margin: 0;
`

const FormInputPriceCompact = styled(FormInputCompact)`
	margin-bottom: -6px;
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
	  -webkit-appearance: none;
	  margin: 0;
	}
`

const IconInput = styled.div`
	& > svg {
		margin: 12px 0px -18px;
		color: black;
		position: relative;
		top: -42px;
		right: -129px;
		font-size: 20px;
	}
	@media(max-width: 992px) {
		& > svg {
			margin: -6px 0;
			right: -148px;
		}
    }
}`

const Form = props => {
	return (
		<FormBody>{ props.children }</FormBody>
	)
}

const FormCompact = props => {
	return (
		<FormBodyCompact
		>{ props.children }</FormBodyCompact>
	)
}

const FormField = props => {
	return (
		<>
			<FormLabel 
				htmlFor = { `to-${ props.label }` }
			>
				{ props.label }
			</FormLabel>
			<FormInput
				type = { props.type }
				id = { props.name }
				name = {  props.name }
				placeholder = {  props.placeholder }
				pattern = { props.pattern }
				inputmode= { props.inputmode }
				value = { props.value }
				onChange = { props.onChange }
			/>
		</>
	)
}

const FormFieldCompact = props => {
	return (
		<>
			<FormLabel 
				htmlFor = { `to-${ props.label }` }
			>
				{ props.label }
			</FormLabel>
			<FormInputCompact
				type = { props.type }
				id = { props.name }
				name = {  props.name }
				placeholder = {  props.placeholder }
				pattern = { props.pattern }
				inputmode= { props.inputmode }
				value = { props.value }
				onChange = { props.onChange }
			/>
		</>
	)
}

const FormSelectField = props => {
	return (
		<>
			<FormLabel
				htmlFor = { `to-${ props.label }` }
			>
				{ props.label }
			</FormLabel>
			<FormSelectCompact
				id = { props.name }
				type = { props.type }
				name = { props.name }
				value = { props.value }
				placeholder = { props.placeholder }
				onChange = { props.onChange }
			>
				{ props.children }
			</FormSelectCompact>
		</>
	)
}

const FormPriceCompact = props => {
	return (
		<>
			<ModalLabel
				htmlFor = { `to-${ props.label }` }
			>
				{ props.label }
			</ModalLabel>
			<FormInputPriceCompact
				autoFocus
				type = 'number'
				id = { props.name }
				name = {  props.name }
				placeholder = {  props.placeholder }
				pattern='[0-9]*'
				value = { props.value }
				onChange = { props.onChange }
				inputMode = 'decimal'
			/>
			<IconInput>
				<Shuffle 
					onClick={ props.onClick }
				/>
			</IconInput>
		</>

	)
}

const FormDateCompact = props => {
	return (
		<FormInputCompact
			type = { props.type }
			id = { props.name }
			name = {  props.name }
			placeholder = {  props.placeholder }
			pattern = { props.pattern }
			inputmode= { props.inputmode }
			value = { props.value }
			onChange = { props.onChange }
		/>
	)
}

const FormBoolCompact = props => {
	return (
		<>
			<BoolLabel 
				htmlFor = { `to-${ props.label }` }
			>
				{ props.label }
			</BoolLabel>
			<BooleanInputContainer
				type = { props.type }
				id = { props.name }
				name = {  props.name }
				placeholder = {  props.placeholder }
				pattern = { props.pattern }
				value = { props.value }
				onChange = { props.onChange }
				checked = { props.checked }
			/>
		</>
	)
}

export { Form, FormField, FormSelectField, 
	FormPriceCompact, FormCompact, 
	FormDateCompact, FormFieldCompact, FormBoolCompact }
