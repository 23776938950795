import React, { Component } from 'react';
import styled from "styled-components";

import { CardContainer } from '../../components/Cards.js'
import { Button } from '../../components/Buttons.js'


const BoardCard = styled(CardContainer)`
	width: 72px;
    height: 72px;
    line-height: 72px;
    font-size: 24px;
`

const BoardContainer = styled.div`
	margin-bottom: 12px;
`

class Board extends Component { 
	constructor(props) {
		super(props)
		this.state = {
			isPlayerOne: true,
			marks: Array(9).fill('-'),
			winner: null
}}

	renderSquare(value) {
		return (
			<BoardCard 
				onClick = { () => this.handleClick(value) }
			>
				{ this.state.marks[value] }
			</BoardCard>
	)}

	handleClick(value) {
		let items = [...this.state.marks]
    	items[value] = this.state.isPlayerOne ? '🍎' : '🍌'		

		if ( !this.state.winner && this.state.marks[value] === '-') {
			this.setState({ marks: items })
			this.checkWinner(items)
			this.setState({ isPlayerOne: !this.state.isPlayerOne })
		}
	}

	checkWinner(board) {
		const conditions = [
			[0,4,8], [2,4,6],
			[1,4,7], [3,4,5],
			[0,1,2], [0,3,6],
			[2,5,8], [6,7,8]
		]

		for ( let i = 0; i < conditions.length; i++ ) {
			if ( board[conditions[i][0]] !== '-' 
				&& board[conditions[i][0]] === board[conditions[i][1]]
				&& board[conditions[i][0]] === board[conditions[i][2]]) {
					let player = this.state.isPlayerOne ?  '🍎' : '🍌'
					this.setState({ winner: player })
					this.modifyTitle(player + ' venceu')
			}
		}
	}

	modifyTitle(text) {
		const h1 = document.querySelector('h1')
		h1.innerText = text
	}

	reset() {
		this.setState({ isPlayerOne: true })
		this.setState({ marks: Array(9).fill('-') })
		this.setState({ winner: null })
		this.modifyTitle('Tic tac toe')
	}
	
	render() {
		return (
			<>
			<BoardContainer>
				<section>
					{ this.renderSquare(0) }
					{ this.renderSquare(1) }
					{ this.renderSquare(2) }
				</section>
				<section>
					{ this.renderSquare(3) }
					{ this.renderSquare(4) }
					{ this.renderSquare(5) }
				</section>
				<section>
					{ this.renderSquare(6) }
					{ this.renderSquare(7) }
					{ this.renderSquare(8) }
				</section>
			</BoardContainer>
			<Button onClick = { () => this.reset() }>
				Resetar
			</Button>
			</>
		)
	}
}

export { Board }