import styled from "styled-components"

import { Colors } from '../theme/Colors.js'
import { FontSizes } from '../theme/Fonts.js'
import { Shadow } from '../theme/BasicStyles.js'


const CardContainer = styled.button`
	margin: 6px;
	color: ${ Colors.Dark };
	background-color: ${ Colors.Light };
	width: 120px;
  height: 120px;
  line-height: 120px;
  font-size: 40px;
  display: inline-block;
  vertical-align: bottom;
  border: 0px;
  border-radius: 3px;
	cursor: pointer;
	${ Shadow }

	@media (max-width: 992px) {
  	width: 100px;
  	height: 100px;
  	line-height: 100px;
  	font-size: 60px;
  	padding: 0px;
	}
`

const Emoji = styled.div`
	font-size: 40px;
	line-height: 120px;

	@media (max-width: 992px) {
		font-size: 32px;
		line-height: 88px;
	}
`

const Label = styled.p`
	position: relative;
  bottom: 42px;
  font-size: 12px;
  line-height: 14px;
  text-transform: initial;

  @media (max-width: 992px) {
  	bottom: 28px;
  }
`

const InfoCardContainer = styled.div`
	background-color: ${ Colors.Light };
	border-radius: 4px;
	padding: 12px;
	text-align: left;
	min-height: 84px;
	max-width: 480px;
	margin: auto;
	margin-bottom: 12px;

	svg {
		float: right;
	}
`

const CardTitle = styled.h3`
  font-family: Montserrat;
  ${ FontSizes.S8 }
  color: ${Colors.Blue};
  font-weight: 900;
  text-align: left;
  padding: 12px 0 0;
  margin: 0;
`

const CardDescription = styled.p`
  font-family: sans-serif;
  ${ FontSizes.S9 }
  color: ${Colors.Gray};
  text-align: left;
  margin-top:0;
  font-weight: regular;
`

const LinkCard = props => {
	return(
		<CardContainer 
			onClick={ () => window.location.href = `${ props.link }` }
		>
			<Emoji>{ props.emoji }</Emoji>
			<Label>{ props.label }</Label>
		</CardContainer>
	)
}

const ActionCard = props => {
	return(
		<CardContainer
			onClick = { props.onClick }
		>
			<Emoji>{ props.emoji }</Emoji>
			<Label>{ props.label }</Label>
		</CardContainer>
	)
}

const InfoCard = props => {
	return(
		<InfoCardContainer>
			<CardTitle>{ props.title }</CardTitle>
			<CardDescription>{ props.text }</CardDescription>
			{ props.children }
		</InfoCardContainer>
	)
}

export { CardContainer, LinkCard, InfoCard, ActionCard }