import React from 'react';
import { 
	SummaryTableContainer, 
	Cell, 
	Header 
} from '../../components/Tables.js';

const SummaryTable = props => {
  return (
    <>
      <SummaryTableContainer id="main-table">
        <thead>
          <tr key='header-key'>
	        	<Header 
			      key = 'item-type' 
			      Align = 'left'
			      HideMobile = { false }
			    >
			    	Tipo
			    </Header>
			    <Header 
			      key = 'item-value'
			      Align = 'right'
			      HideMobile = { false }
			    >
			    	Gasto (Orçamento do mês)
			    </Header>
         </tr>
        </thead>
        <tbody>
          {RenderRows(
          	props.SummarizedData,
          	props.CategoriesBudget,
          	props.Budget
          )}
        </tbody>
      </SummaryTableContainer>
    </>
  )
}

const RenderRows = (SummarizedData, CategoriesData, Budget) => {
	const DataWithoutTotal = [...SummarizedData]
	DataWithoutTotal.shift()
  return (
    DataWithoutTotal.map((item, index) => (
    	<tr key={'rows-key' + Object.keys(item)}>
	      <Cell 
	        Align = 'left'
	        HideMobile = { false }
	      >
	        { Object.keys(item) }
	      </Cell>
	      <Cell 
	        Align = 'right'
	        HideMobile = { false }
	      >{ 
	      	'R$ ' + 
	        parseFloat(Object.values(item))
	        .toFixed(2)
	        .replace('.', ',') +
	        ' (R$ ' +
	        parseFloat(CategoriesData[Object.keys(item)] * Budget)
	        .toFixed(2)
	        .replace('.', ',') +
	        ')'
	      }
	      </Cell>
      </tr>
    ))
  )
}

export  { SummaryTable }