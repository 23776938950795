import React, { Component } from 'react'
import { getDatabase, ref, set, push, serverTimestamp } from 'firebase/database'
import { Form, FormField } from '../../components/Forms.js'
import { PrimaryButton } from '../../components/Buttons.js'




export class LeadForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			name: null,
			email: null,
		}
	}

	postLead = () => {
		const db = getDatabase()
		
		const postLeadsRef = ref(db, 'leads')
		const newPostRef = push(postLeadsRef)
		
		set(newPostRef, {
			name: this.state.name,
    		email: this.state.email,
    		createdAt: serverTimestamp(),
    		updatedAt: serverTimestamp()
		})
	}

	render() {
		return(
			<Form>
		        <FormField
		        	label = "Nome"
		        	type = "text"
		        	id = "name"
		        	name = "name"
		        	placeholder = "John Doe"
			        onChange = { inp => this.setState( { name: inp.target.value }) }
		        />
		        <FormField
		        	label = "Email"
		        	type = "email"
		        	id = "email"
		        	name = "email"
		        	placeholder = "john.doe@gmail.com"
			        onChange = { inp => this.setState( { email: inp.target.value }) }
		        />
	    	    <PrimaryButton 
	    	    	onClick = { () => this.postLead() }
	    	    >
	    	    	Confirmar Inscrição
	    	    </PrimaryButton>
		    </Form>
		)
	}
}