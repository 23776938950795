export const SetFormBody = (inputs, values) => {   
  const inputKeys = Object.keys(inputs)

  let form = {}
  inputKeys.map((item, index) => (
    form[item] = inputs[item]
  ))

  let body = {
    ...form,
    id: {
      name: 'id', 
      value: values.id || ''
    },
  }

  inputKeys.map((item, index) => (
    body = {
      ...body,
      [item] : {
        ...body[item],
        value: values[item] || ''
      }
    }
  ))
  return body
}