import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../providers/Auth.js'
import { ModalContext } from '../../providers/Modal.js'
import { useDetailedSummary } from './SummaryHooks.js'
import { useFinancesDetails } from './TransactionHooks.js'
import { TransactionTable } from './TransactionTables.js'
import { TransactionForm } from './TransactionForm.js'
import { SetFormBody } from './FormBodyHelper.js'
import { OneColumnContent } from '../../components/ContentAreas.js'
import { SummaryHeader } from '../../components/SummaryHeader.js'
import { NavTitle } from '../../components/Titles.js'
import { Button } from '../../components/Buttons.js'
import { DefaultModal } from '../../components/Modals.js'
import { FooterButton } from '../../components/Buttons.js'
import { Colors } from '../../theme/Colors.js'
import { SavingInputs } from './InputRules.js'

const Savings = () => {
  const { user } = useContext(AuthContext)
  const { modal, setModal } = useContext(ModalContext)
  const [pagination, setPagination] = useState(20)
  const [total, setTotal] = useState(0)
  const today = new Date()
  const path = 'savings'
  const savingsSummary = useDetailedSummary(path, user, today.getMonth(), today.getFullYear(), 100)
  const rows = useFinancesDetails(path, user, pagination)

  useEffect (() => {
    const newSavingsTotal = savingsSummary.length > 0 ? savingsSummary[0].total : 0
    setTotal(newSavingsTotal)
  }, [savingsSummary])

  const handleAddSavingClick = () => {
    const today = new Date()
    setModal({
      ...modal,
      title: 'Adicionar',
      display: null,
      body: (
        <TransactionForm
          Path={path}
          InputFields={SetFormBody(SavingInputs, { date: today.toISOString().slice(0, 10) })}
        />
      ),
    })
  }

  const handleLoadMoreClick = () => {
    setPagination(pagination + 20)
  }

  return (
    <>
      <OneColumnContent>
        <NavTitle>Economias</NavTitle>
        <SummaryHeader
          heading="Esse mês"
          title={'R$ ' + parseFloat(total).toFixed(2).replace('.', ',')}
          caption="Clique para adicionar economia"
          onClick={handleAddSavingClick}
        />
        <TransactionTable
          Path={path}
          DisplayColumns={['category', 'fund', 'date', 'value']}
          Rows={rows}
          Colors={[Colors.Red, Colors.Green]}
          Inputs={SavingInputs}
          Form={transactionData => SetFormBody(SavingInputs, transactionData)}
        />
        <Button onClick={handleLoadMoreClick}>Carregar mais</Button>
      </OneColumnContent>
      <DefaultModal />
      <FooterButton />
    </>
  )
}

export default Savings