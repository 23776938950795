import React from 'react';
import styled from "styled-components"

import { Colors } from '../theme/Colors.js'
import { Shadow, IconStyle } from '../theme/BasicStyles.js'
import { AuthButton } from '../components/Authentication.js'

import Home from '@mui/icons-material/Home'


const NavContainer = styled.div`
	z-index: 5;
	position: fixed;
    background-color: rgba(50,72,190,1);
    top: 0;
    width: 100%;
    ${ Shadow }
    @media (max-width: 992px) {
    	display: none;
	}
`

const NavBtn = styled.button`
    cursor: pointer; 
    border: none;
    background-color: transparent;
    vertical-align: middle;
    padding: 24px;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;

    &:hover {
        background-color:rgba(0,0,0,0.05);
    }
`

const Dropdown = styled.div`
    position: relative;
    display: inline-block;
`

const DropdownBtn = styled.button`
    color: white;
    background-color: transparent;
    vertical-align: middle;
    border: none;
    padding: 24px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 900;
    text-transform: uppercase;
`

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: ${ Colors.Light };
    ${ Shadow } 

    ${ Dropdown }:hover & {
		display: block;
		margin: 0;
	}
`

const DropdownLink = styled.a`
    display: block;
    min-width: 200px;
    padding: 18px;
    text-decoration: none;
    font-family: sans-serif;
    text-transform: uppercase;
    color: black;
    font-size: 12px;
    font-weight: 900;
    
    &:hover {
    	background-color:#ddd;
	}
`

const NavBar = () => {
    return(
        <NavContainer>
        <NavBtn onClick={ () => window.location.href ='/' }>
            <Home style={ IconStyle }></Home>
        </NavBtn>
        <Dropdown>
            <DropdownBtn>Páginas</DropdownBtn>
            <DropdownContent>
                <DropdownLink 
                    href="/tictactoe"
                >
                    Tic Tac Toe
                </DropdownLink>
                <DropdownLink 
                    href="https://materiais.goncguilherme.com/aula-gratis"
                >
                    Aula Grátis
                </DropdownLink>
                <DropdownLink 
                    href="/custom-form"
                >
                    Formulário Integrado
                </DropdownLink>
                <DropdownLink 
                    href="/embed-form"
                >
                    Formulário Embutido
                </DropdownLink>
            </DropdownContent>
        </Dropdown>
        <AuthButton/>
    </NavContainer>
    )
}

export default NavBar
