import React  from 'react';
import styled from "styled-components";


const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  min-height: calc(100vh - 64px);
  width: 100%;
  position: absolute;
  top: 64px;
  background-image: url("https://images.unsplash.com/photo-1513569771920-c9e1d31714af");
  background-size: cover;

  @media (max-width: 992px) {
    top: 0px;
    min-height: calc(100vh);
  }
`

const Background = props => {
  return (
    <BackgroundContainer>
        {props.children}
    </BackgroundContainer>
  )
}

export default Background
