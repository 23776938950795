import React from 'react';

import { OneColumnContent } from '../../components/ContentAreas.js'
import { PageTitle } from '../../components/Titles.js'
import { Board } from './TicTacToeSettings.js'
import { FooterButton } from '../../components/Buttons.js'


export default function TicTacToe() {
  return (
    <>
        <OneColumnContent>
          <PageTitle>Tic tac toe</PageTitle>
          <Board/>
        </OneColumnContent>
        <FooterButton/>
    </>
  )
}