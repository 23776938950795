import React  from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthStatus } from './components/Authentication.js'

import './theme/Styles.css';

import Background from './components/Background.js'
import NavBar from './components/NavBar.js'
import Home from './pages/Home.js'
import Expenses from './pages/finances/Expenses.js'
import RecurringExpenses from './pages/finances/RecurringExpenses.js'
import Savings from './pages/finances/Savings.js'
import Budgets from './pages/finances/Budgets.js'

import Profile from './pages/settings/Profile.js'

import TicTacToe from './pages/playground/TicTacToe.js'
import EmbedForm from './pages/forms/EmbedForm.js'
import CustomForm from './pages/forms/CustomForm.js'

import Page404 from './pages/404.js'


const App = () => {
  return (
    <>
    <AuthStatus/>
    <BrowserRouter>
      <NavBar/>
      <Background>
        <Routes>
          <Route path='/' element={< Home />}></Route>
          <Route path='/expenses' element={< Expenses />}></Route>
          <Route path='/recurring-expenses' element={< RecurringExpenses />}></Route>
          <Route path='/savings' element={< Savings />}></Route>
          <Route path='/budgets' element={< Budgets />}></Route>
          <Route path='/tictactoe' element={< TicTacToe />}></Route>
          <Route path='/embed-form' element={< EmbedForm />}></Route>
          <Route path='/custom-form' element={ < CustomForm />}></Route>
          <Route path='/profile' element={ < Profile />}></Route>
          <Route path="*" element={< Page404 />}></Route>
        </Routes>
      </Background>
    </BrowserRouter>
    </>
  )
}

export default App