import React from 'react'
import styled from "styled-components";

import { Colors } from '../theme/Colors.js'
import { FontSizes } from '../theme/Fonts.js'
import { Shadow } from '../theme/BasicStyles.js'
import { PageSecondaryTitle } from '../components/Titles.js'
import Add from '@mui/icons-material/Add'


const SummaryContainer = styled.button`
	color: ${ Colors.Dark };
	background-color: ${ Colors.Light };
	margin: 0 0 12px;
	width: 100%;
  display: inline-block;
  border: 0px;
  border-radius: 3px;
	${ Shadow }
	padding: 24px;
	cursor: pointer;

	svg {
		float: right;
	}

	&:hover {
		background-color: ${Colors.Hover};
	}
`

const SmallSummaryContainer = styled(SummaryContainer)`
	max-width: 186px;
	margin: 0 6px 12px;
	padding: 12px;
	height: 160px;

	@media(max-width: 992px) {
  	padding: 6px;
  	max-width: 156px;
  }
`

const CardLabel = styled.p`
  ${ FontSizes.S9 }
  color: ${Colors.Black};

  font-weight: 900;
  text-align: left;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
`

const SmallCardLabel = styled(CardLabel)`
  ${ FontSizes.S10 }
	color: ${ Colors.Light };

 @media(max-width: 992px) {
	  ${ FontSizes.S10 }
  }
`

const CardTitle = styled(PageSecondaryTitle)`
	color: ${ Colors.Dark };
	text-align: left;
	padding: 0;
`

const SmallCardTitle = styled(CardTitle)`
	color: ${ Colors.Light };
  ${ FontSizes.S6 }
	margin: 0 0 6px;

	@media(max-width: 992px) {
	  ${ FontSizes.S7 }
  }
`

const Caption = styled.p`
	color: ${ Colors.Gray };
	text-align: left;
	padding: 0;
	margin-bottom: 0;
`

const SmallCaption = styled(Caption)`
	color: ${ Colors.Light };
  ${ FontSizes.S10 }
  margin-top: 0px;
`

const SummaryHeader = props => {
	return (
		<SummaryContainer
			onClick = { props.onClick }
		>
		<Add/>
			<CardLabel>
				{ props.heading }
			</CardLabel>
			<CardTitle>
				{ props.title }
			</CardTitle>
			<Caption>
				{ props.caption }
			</Caption>
		</SummaryContainer>
	)
}

const SummaryHeaderDashboard = props => {
	return (
		<SmallSummaryContainer
			onClick = { props.onClick }
			style={{backgroundColor: props.background}}
		>
			<SmallCardLabel>
				{ props.heading }
			</SmallCardLabel>
			<SmallCardTitle>
				{ props.title }
			</SmallCardTitle>
			<SmallCaption>
				{ props.caption }
			</SmallCaption>
		</SmallSummaryContainer>
	)
}

export  { SummaryHeader, SummaryHeaderDashboard }
